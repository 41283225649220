///from library escape-string-regexp
const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;
const escapeStringRegexp = str => {
    if (typeof str !== 'string') {
        throw new TypeError('Expected a string');
    }

    return str.replace(matchOperatorsRe, '\\$&');
};

//from library trim-repeated
const trimRepeated = (str, target) => {
    if (typeof str !== 'string' || typeof target !== 'string') {
        throw new TypeError('Expected a string');
    }

    return str.replace(new RegExp('(?:' + escapeStringRegexp(target) + '){2,}', 'g'), target);
};

//from library filename-reserved-regex
/* eslint-disable no-control-regex */
const filenameReservedRegex = () => /[<>:"\/\\|?*\x00-\x1F]/g;
filenameReservedRegex.windowsNames = () => /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i;

//const stripOuter = require('strip-outer');
//from library strip-outer
const stripOuter = (input, substring) => {
    if (typeof input !== 'string' || typeof substring !== 'string') {
        throw new TypeError('Expected a string');
    }

    substring = escapeStringRegexp(substring);
    return input.replace(new RegExp(`^${substring}|${substring}$`, 'g'), '');
};

// Doesn't make sense to have longer filenames
const MAX_FILENAME_LENGTH = 100;

const reControlChars = /[\u0000-\u001f\u0080-\u009f]/g; // eslint-disable-line no-control-regex
const reRelativePath = /^\.+/;

const filenamify = (string, options = {}) => {
    if (typeof string !== 'string') {
        throw new TypeError('Expected a string');
    }

    const replacement = options.replacement === undefined ? '!' : options.replacement;

    if (filenameReservedRegex().test(replacement) && reControlChars.test(replacement)) {
        throw new Error('Replacement string cannot contain reserved filename characters');
    }

    string = string.replace(filenameReservedRegex(), replacement);
    string = string.replace(reControlChars, replacement);
    string = string.replace(reRelativePath, replacement);

    if (replacement.length > 0) {
        string = trimRepeated(string, replacement);
        string = string.length > 1 ? stripOuter(string, replacement) : string;
    }

    string = filenameReservedRegex.windowsNames().test(string) ? string + replacement : string;
    string = string.slice(0, MAX_FILENAME_LENGTH);

    return string;
};

export { filenamify };

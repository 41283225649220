import {
    MODE_REPORTS_CREATE_FAILED,
    MODE_REPORTS_CREATE_PENDING,
    MODE_REPORTS_CREATE_SUCCESS,
    MODE_REPORTS_FETCH_FAILED,
    MODE_REPORTS_FETCH_PENDING,
    MODE_REPORTS_FETCH_SUCCESS,
    MODE_REPORTS_PARAM_TYPES_FETCH_SUCCESS,
    MODE_REPORTS_ROLES_FAILED,
    MODE_REPORTS_ROLES_PENDING,
    MODE_REPORTS_ROLES_SUCCESS,
    MODE_REPORTS_SET_SIGNED_URL,
    MODE_REPORTS_UPDATE_FAILED,
    MODE_REPORTS_UPDATE_PENDING,
    MODE_REPORTS_UPDATE_SUCCESS,
} from '../actions/modeReports';

import axios from 'axios';

const fetchPending = () => ({ type: MODE_REPORTS_FETCH_PENDING });

const fetchSuccess = response => ({
    type: MODE_REPORTS_FETCH_SUCCESS,
    payload: response.data,
});

const fetchError = error => ({
    type: MODE_REPORTS_FETCH_FAILED,
    payload: error,
});

export const fetchModeReports = partnerId => dispatch => {
    dispatch(fetchPending());
    return axios.get('/api/v1/partners/' + partnerId + '/reports/mode').then(
        response => {
            dispatch(fetchSuccess(response));
        },
        error => {
            dispatch(fetchError(error));
        }
    );
};

export const fetchModeReportsAdmin = () => dispatch => {
    dispatch(fetchPending());
    return axios.get('/api/v1/mode-reports').then(
        response => {
            dispatch(fetchSuccess(response));
        },
        error => {
            dispatch(fetchError(error));
        }
    );
};

export const setSignedUrl = url => ({
    type: MODE_REPORTS_SET_SIGNED_URL,
    payload: url,
});

const paramTypesFetched = types => ({
    type: MODE_REPORTS_PARAM_TYPES_FETCH_SUCCESS,
    payload: types,
});

export const fetchParamTypes = () => dispatch => {
    return axios.get('/api/v1/mode-reports/parameter-types').then(response => {
        dispatch(paramTypesFetched(response.data));
    });
};

const createPending = () => ({ type: MODE_REPORTS_CREATE_PENDING });

const createSuccess = response => ({
    type: MODE_REPORTS_CREATE_SUCCESS,
    payload: response.data,
});

const createError = error => ({
    type: MODE_REPORTS_CREATE_FAILED,
    payload: error,
});

export const createModeReport = (report, history) => dispatch => {
    dispatch(createPending());
    axios
        .post('/api/v1/mode-reports', report)
        .then(response => {
            dispatch(createSuccess(response));
            history.push('/' + response.data.id);
        })
        .catch(error => {
            dispatch(createError(error));
        });
};

const updatePending = () => ({ type: MODE_REPORTS_UPDATE_PENDING });

const updateError = error => ({
    type: MODE_REPORTS_UPDATE_FAILED,
    payload: error,
});

const updateSuccess = report => ({
    type: MODE_REPORTS_UPDATE_SUCCESS,
    payload: report,
});

export const addPermissions = (reportId, roleId, partnerIds) => dispatch => {
    dispatch(updatePending());
    return axios
        .put('/api/v1/mode-reports/' + reportId + '/permissions/role', {
            roleId,
            partnerIds,
        })
        .then(response => {
            dispatch(updateSuccess(response.data));
        })
        .catch(error => {
            dispatch(updateError(error));
        });
};

export const removeRolePermission = (reportId, roleId, partnerId) => dispatch => {
    dispatch(updatePending());
    return axios
        .delete('/api/v1/mode-reports/' + reportId + '/permissions/role', {
            data: {
                roleId,
                partnerId,
            },
        })
        .then(response => {
            dispatch(updateSuccess(response.data));
        })
        .catch(error => {
            dispatch(updateError(error));
        });
};

export const addUserPermission = (reportId, userId, partnerId) => dispatch => {
    dispatch(updatePending());
    return axios
        .put('/api/v1/mode-reports/' + reportId + '/permissions/user', {
            userId,
            partnerId,
        })
        .then(response => {
            dispatch(updateSuccess(response.data));
        })
        .catch(error => {
            dispatch(updateError(error));
        });
};

export const removeUserPermission = (reportId, userId, partnerId) => dispatch => {
    dispatch(updatePending());
    return axios
        .delete('/api/v1/mode-reports/' + reportId + '/permissions/user', {
            data: {
                userId,
                partnerId,
            },
        })
        .then(response => {
            dispatch(updateSuccess(response.data));
        })
        .catch(error => {
            dispatch(updateError(error));
        });
};

const fetchRolesPending = () => ({
    type: MODE_REPORTS_ROLES_PENDING,
});

const fetchRolesSuccess = roles => ({
    type: MODE_REPORTS_ROLES_SUCCESS,
    payload: roles,
});

const fetchRolesError = error => ({
    type: MODE_REPORTS_ROLES_FAILED,
    payload: error,
});

export const fetchRoles = () => dispatch => {
    dispatch(fetchRolesPending());
    axios
        .get('/api/v1/mode-reports/roles')
        .then(response => {
            dispatch(fetchRolesSuccess(response.data));
        })
        .catch(error => {
            dispatch(fetchRolesError(error));
        });
};

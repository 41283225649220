import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DxPopup from '../../../global/components/DxPopup';
import DxTypeahead from '../../../global/components/DxTypeahead';
import Input from '../../../global/components/Input';

export default class AddRole extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        addRole: PropTypes.func.isRequired,
        partners: PropTypes.array.isRequired,
        roles: PropTypes.array.isRequired,
    };

    state = {
        roleId: null,
        partnerIds: [],
        addRoleForAll: false,
    };

    addRoles = () => {
        if (this.state.roleId && this.state.partnerIds.length) {
            this.props.addRole(this.state.roleId, this.state.partnerIds); // pass state
        }
    };

    toggleAddForAll = () => {
        this.setState(state => ({
            addRoleForAll: !state.addRoleForAll,
        }));
    };

    onClose = () => {
        this.props.onClose();
    };

    partnerSelected = partnerName => {
        const partnerId = partnerName.split('-')[0].trim();
        this.setState(state => ({
            partnerIds: [parseInt(partnerId, 10)],
        }));
    };

    roleSelected = (name, value) => {
        this.setState({
            roleId: value,
        });
    };

    render() {
        console.log(this.state);
        return (
            <DxPopup
                title="Legg til rolle på partner"
                onPopupClose={this.onClose}
                onPopupConfirm={this.addRoles}
                confirmDisabled={this.state.partnerIds.length === 0 || this.state.roleId === null}
            >
                <div className="container-fluid">
                    <div className="form-group">
                        <Input
                            type="checkbox"
                            name="addRoleForAll"
                            label="Legg til for alle partnere (enda ikke støttet)"
                            value={this.state.addRoleForAll}
                            onChange={this.toggleAddForAll}
                        />
                    </div>
                    <div className="form-group">
                        <DxTypeahead
                            disabled={this.state.addRoleForAll}
                            label="Partner"
                            suggestions={this.props.partners.map(p => p.id + ' - ' + p.name)}
                            onSuggestionSelect={this.partnerSelected}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type="select"
                            name="roles"
                            label="Rolle"
                            data={this.props.roles.map(role => ({
                                value: role.id,
                                label: role.name,
                            }))}
                            value={this.state.roleId}
                            onChange={this.roleSelected}
                        />
                    </div>
                </div>
            </DxPopup>
        );
    }
}

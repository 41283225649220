import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { t } from '../../../global/locale';

export default class ActiveHeaderButton extends Component {
    static propTypes = {
        /** @type {String} value - the value (ie text) for the button */
        value: PropTypes.string.isRequired,
        /** @type {Function} onClick - the function to run when the button is clicked */
        onClick: PropTypes.func.isRequired,
        /** @type {String} icon - the name of the icon to use */
        icon: PropTypes.string,
        /** @type {Boolean} disabled - whether this tool is disabled or not */
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    render() {
        return (
            <button onClick={this.props.onClick} disabled={this.props.disabled}>
                <span className={'active-header-button-icon ' + this.props.icon} />
                <span>{t(this.props.value)}</span>
            </button>
        );
    }
}

import React from 'react';

const UserPermission = ({ userId, partnerId, removeUser }) => {
    const remove = () => removeUser(userId, partnerId);
    return (
        <p key={userId}>
            Bruker {userId} på partner {partnerId}{' '}
            <span role="button" onClick={remove}>
                (-)
            </span>
        </p>
    );
};

export default UserPermission;

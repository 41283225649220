import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserPermission from './UserPermission';

export default class ListUserPermissions extends Component {
    static propTypes = {
        permissions: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    removeUser = (userId, partnerId) => {
        this.props.removeUserPermission(userId, partnerId);
    };

    render() {
        const { permissions } = this.props;
        return (
            <div>
                {Object.keys(permissions).map(partnerId => {
                    return permissions[partnerId].users.map(userId => (
                        <UserPermission
                            key={userId + '-' + partnerId}
                            userId={userId}
                            partnerId={partnerId}
                            removeUser={this.removeUser}
                        />
                    ));
                })}
            </div>
        );
    }
}

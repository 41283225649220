import React from 'react';
import PropTypes from 'prop-types';
import DxPopup from '../../../global/components/DxPopup';

const ExportDialog = props => (
    <DxPopup title="Eksportér som" showCancelBtn={false} showConfirmBtn={false} onPopupClose={props.close} large>
        <div className="crm-export-dialog form-group">
            <p>
                Datasettet inneholder <span className="num-records">{props.numRecords} oppføringer</span>. Dette er
                kunder som har godtatt å motta tilbud og informasjon på epost.
            </p>

            <div className="flex-container-row" style={{ justifyContent: 'space-around' }}>
                <button className="export-excel-btn" onClick={props.onClickExport.bind(null, 'xlsx')} />
                <button className="export-csv-btn" onClick={props.onClickExport.bind(null, 'csv')} />
                <button className="export-txt-btn" onClick={props.onClickExport.bind(null, 'txt')} />
            </div>
        </div>
    </DxPopup>
);

ExportDialog.propTypes = {
    onClickExport: PropTypes.func.isRequired,
    numRecords: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
};

export default ExportDialog;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportListItem from './ReportListItem';

export default class ReportList extends Component {
    static propTypes = {
        reports: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    render() {
        return (
            <div className="mode-reports-list">
                {Object.values(this.props.reports).map(report => (
                    <ReportListItem key={report.id} report={report} />
                ))}
            </div>
        );
    }
}

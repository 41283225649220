import Backbone from 'backbone';
import $ from 'jquery';
import _ from 'lodash';
import { makeUnsubscribableCollection } from '../utils/backboneUtils';
import dx from '../dx';

export const getCrmSearchResultsCollection = makeUnsubscribableCollection(
    [],
    () => new CrmSearchResultCollection(null, {})
);

// NOTE: Had to get rid of the Backbone.Collection and write one
// myself that is simpler and doesn't get a lot of processing
// overhead when receiving a big result.

let toTitleCase = function(str) {
    if (str && typeof str === 'string') {
        return str.replace(/[^\s\-][^\s\-]*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
    return '';
};

let CrmSearchResultCollection = function() {
    this.filters = {
        dateFrom: null,
        dateTo: null,
        dateFilterType: null,
        shows: null,
        postalFrom: null,
        postalTo: null,
        numPurchases: null,
    };
    this.data = [];
    this.length = 0;
    this.synced = false;
    this.fetchedFilters = null;
    this.loading = false;
};

_.extend(CrmSearchResultCollection.prototype, Backbone.Events);

// Returns the filters how they was last time the collection
// was fetched
CrmSearchResultCollection.prototype.getFetchedFilters = function() {
    return this.fetchedFilters;
};

CrmSearchResultCollection.prototype.map = function() {
    return this.data.map.apply(this.data, arguments);
};

CrmSearchResultCollection.prototype.getFilters = function() {
    return this.filters;
};

CrmSearchResultCollection.prototype.setFilters = function(newFilters) {
    this.filters = newFilters;
};
// Needed for backboneUtils
CrmSearchResultCollection.prototype.hasSynced = function() {
    return this.synced;
};

CrmSearchResultCollection.prototype.getTypeForDebugging = function() {
    return 'crm';
};

CrmSearchResultCollection.prototype.fetch = function() {
    let filters = _.extend({}, this.filters);
    for (let i in filters) {
        if (filters[i] === null) {
            delete filters[i];
        }
    }

    if (filters.shows && Array.isArray(filters.shows)) {
        filters.shows = filters.shows.join(',');
    }
    this.loading = true;
    return $.ajax({
        data: filters,
        dataType: 'json',
        url: '/api/v1/partners/' + dx.utils.getPartnerId() + '/crm/get',
        method: 'post',
    }).then(
        function(data) {
            this.loading = false;
            this.data = this.parseRecords(data);
            this.length = this.data.length;
            this.synced = true;
            this.trigger('sync');
            this.trigger('reset');
        }.bind(this)
    );
};

CrmSearchResultCollection.prototype.toJSON = function() {
    return this.data;
};

CrmSearchResultCollection.prototype.reset = function() {
    this.data = [];
    this.length = 0;
    this.trigger('reset');
};

CrmSearchResultCollection.prototype.parseRecords = function(data) {
    return data.data.map(function(record) {
        if (typeof record.profile.firstName !== 'string') {
            record.profile.firstName = '';
        }

        record.firstName = toTitleCase(record.profile.firstName);
        if (typeof record.profile.lastName !== 'string') {
            record.profile.lastName = '';
        }
        record.lastName = toTitleCase(record.profile.lastName);
        record.email = record.profile.email;
        record.mobilePhone = record.profile.mobilePhone;
        record.tickets = _.size(record.profile.tickets);
        record.purchases = _.size(record.profile.purchases);
        record.totalAmount = record.profile.totalAmount;
        /*
        if (record.purchases > 0) {
            record.totalAmount = _.reduce(record.profile.purchases, (sum, n) => {
                return sum + n;
            }, 0);
        } else {
            record.totalAmount = 0;
        }*/
        return record;
    });
};

export default CrmSearchResultCollection;

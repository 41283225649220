import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Input from '../../../global/components/Input';
import dx from '../../../global/dx';

const { func, array, object } = PropTypes;

const DATE_FORMAT = 'DD.MM.YYYY';

const toSortableDateFormat = function(date) {
    if (date === null) {
        return null;
    }
    const momentDate = moment(date, DATE_FORMAT);
    return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null;
};

const isDateBetween = function(date, from = null, to = null) {
    if (!date) return false;
    const momentDate = moment(date, 'YYYY-MM-DD');
    if (from && to) {
        const momentFrom = moment(from, DATE_FORMAT);
        const momentTo = moment(to, DATE_FORMAT);
        if (!momentFrom.isValid() || !momentTo.isValid()) return false;
        return momentDate.isBetween(momentFrom, momentTo, null, '[]'); //inclusion [] - doesn't work, we need to update moment version to 2.13.0 +
    } else if (from) {
        const momentFrom = moment(from, DATE_FORMAT);
        if (!momentFrom.isValid()) return false;
        return momentDate.isSameOrAfter(momentFrom);
    } else if (to) {
        const momentTo = moment(to, DATE_FORMAT);
        if (!momentTo.isValid()) return false;
        return momentDate.isSameOrBefore(momentTo);
    }
    return true;
};

const findIndexDateLessThan = function(shows, date, fromIndex = null) {
    let index = shows.findIndex(function(show, index) {
        if (index < fromIndex) {
            return false;
        }
        return isDateBetween(show.date, null, date);
    });
    return index;
};

class CrmFilters extends React.PureComponent {
    static propTypes = {
        collection: array.isRequired,
        shows: array.isRequired,
        renters: array.isRequired,
        getFilters: func.isRequired,
        setFilters: func.isRequired,
        fetchResults: func.isRequired,
        presetFilters: object,
    };

    constructor(props) {
        super(props);

        this.presetFilters = null; // Save to compare received filters to avoid infinit loop! :p

        this.state = {
            dateFilterType: 'profile', // ['profile', 'ticket', 'show']
            renterIds: [],
            showIds: [],
            showTypes: [],
            dateFrom: moment().subtract(1, 'months'),
            dateFromError: null,
            dateTo: moment(),
            dateToError: null,
            postalFrom: null,
            postalTo: null,
            marketingAgreement: 2,
            gdprAgreement: true,
            numPurchases: 1,
            postalFromError: null,
            postalToError: null,
            partnerId: dx.utils.getPartnerId(),
        };
    }

    // If users clicks on one of the preset filters suggested initially
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.presetFilters && nextProps.presetFilters !== this.presetFilters) {
            this.presetFilters = nextProps.presetFilters;

            this.setState(
                {
                    ...this.state,
                    ...nextProps.presetFilters,
                },
                () => {
                    this.updateFilters();
                    this.props.fetchResults();
                }
            );
        }
    }

    // Also reset showIds when changing type
    onTypeChange = (name, value) => {
        this.setState({ [name]: value, showIds: '' });
    };

    onFilterChange = (name, value) => {
        this.setState({ [name]: value }, () => {
            this.validate(name);
        });
    };

    onRadioChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    onCheckBoxChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    onMultiChange = (name, value) => {
        this.setState({ [name]: value, showIds: '' }, () => {
            this.updateFilters();
        });
    };

    onShowsChange = (name, value) => {
        this.setState({ showIds: value }, () => {
            this.updateFilters();
        });
    };

    updateFilters = () => {
        if (this.state.dateFilterType === 'profile' || this.state.dateFilterType === 'ticket') {
            this.props.setFilters({
                dateFilterType: this.state.dateFilterType,
                dateFrom: toSortableDateFormat(this.state.dateFrom),
                dateTo: toSortableDateFormat(this.state.dateTo),
                postalFrom: this.state.postalFrom,
                marketingAgreement: this.state.marketingAgreement,
                gdprAgreement: this.state.gdprAgreement,
                postalTo: this.state.postalTo,
                numPurchases: this.state.numPurchases,
                partnerId: this.state.partnerId,
            });
        } else {
            this.props.setFilters({
                // The user can explicitly select shows, but if he doesn't, we
                // search among all shows matching the other criterias:
                shows: this.state.showIds.length
                    ? this.state.showIds.map(obj => obj.value)
                    : this.getAvailableShows().map(show => show.id),
                postalFrom: this.state.postalFrom,
                postalTo: this.state.postalTo,
                marketingAgreement: this.state.marketingAgreement,
                gdprAgreement: this.state.gdprAgreement,
                numPurchases: this.state.numPurchases,
                partnerId: this.state.partnerId,
            });
        }
    };

    validate = fieldName => {
        // So far only validate postal
        if (fieldName === 'postalFrom' || fieldName === 'postalTo') {
            const value = this.state[fieldName];
            if (value && (value < 0 || value > 9999)) {
                this.setState({ [`${fieldName}Error`]: 'Tall mellom 0000 og 9999' });
            } else {
                this.setState({ [`${fieldName}Error`]: null });
            }
        }
        if (fieldName === 'dateTo' || fieldName === 'dateFrom') {
            let momentFrom, momentTo;
            momentTo = momentFrom = false;
            if (this.state['dateFrom']) {
                momentFrom = moment(this.state['dateFrom'], DATE_FORMAT);
            }
            if (this.state['dateFrom'] && null === this.state['dateTo']) {
                this.setState({ ['dateTo']: momentFrom.add(1, 'months') });
            }
            if (this.state['dateTo']) {
                momentTo = moment(this.state['dateTo'], DATE_FORMAT);
            }
            if (this.state['dateTo'] && null === this.state['dateFrom']) {
                this.setState({ ['dateFrom']: momentTo.subtract(1, 'months') });
            }

            if (false !== momentFrom && false !== momentTo) {
                if (!momentTo.isSameOrAfter(momentFrom)) {
                    this.setState({
                        [`dateToError`]: '"Til" må være etter "Før"',
                        [`dateFromError`]: '"Til" må være etter "Før"',
                    });
                } else {
                    this.setState({ [`dateToError`]: null, [`dateFromError`]: null });
                }
            } else {
                if (false !== momentFrom && false !== momentTo && Math.abs(momentFrom.diff(momentTo, 'days')) > 365) {
                    this.setState({
                        [`${fieldName}Error`]: 'Du kan ikke hente data fra en periode på mer enn et år om gangen ',
                    });
                } else {
                    this.setState({ [`dateFromError`]: null, [`dateToError`]: null });
                }
            }
        }
    };

    render() {
        return (
            <div>
                <div className="form-group">
                    <Input
                        type="select"
                        label="Type søk:"
                        value={this.state.dateFilterType}
                        name="dateFilterType"
                        data={[
                            { value: 'profile', label: 'Samtykket' },
                            { value: 'ticket', label: 'Billett kjøpt' },
                            { value: 'show', label: 'Arrangement' },
                        ]}
                        onChange={this.onTypeChange}
                        onBlur={this.updateFilters}
                    />
                </div>

                <div className="form-group">
                    <Input
                        type="date"
                        label="Fra:"
                        placeholder="År 0"
                        value={this.state.dateFrom}
                        error={this.state.dateFromError}
                        name="dateFrom"
                        onChange={this.onFilterChange}
                        onBlur={this.updateFilters}
                    />
                </div>

                <div className="form-group">
                    <Input
                        type="date"
                        label="Til:"
                        placeholder="The end"
                        value={this.state.dateTo}
                        error={this.state.dateToError}
                        name="dateTo"
                        onChange={this.onFilterChange}
                        onBlur={this.updateFilters}
                    />
                </div>

                {this.state.dateFilterType === 'show' && (
                    <div>
                        {this.renderRenterFilter()}
                        {this.renderShowsTypeFilter()}
                        {this.renderShowsFilter()}
                    </div>
                )}

                <div className="form-group">
                    <Input
                        type="radio"
                        name="marketingAgreement"
                        value={this.state.marketingAgreement}
                        label="Samtykker"
                        data={[
                            { label: 'Markedsføringssamtykke', value: 1 },
                            { label: 'Segmentert Markedsføringssamtykke', value: 2 },
                        ]}
                        onChange={this.onRadioChange}
                    />
                </div>

                <div className="form-group">
                    <Input
                        type="number"
                        label="Postnummer fra og med:"
                        placeholder="0000"
                        value={this.state.postalFrom}
                        error={this.state.postalFromError}
                        name="postalFrom"
                        onChange={this.onFilterChange}
                        onBlur={this.updateFilters}
                    />
                </div>

                <div className="form-group">
                    <Input
                        type="number"
                        label="Postnummer til og med:"
                        placeholder="9999"
                        value={this.state.postalTo}
                        name="postalTo"
                        error={this.state.postalToError}
                        onChange={this.onFilterChange}
                        onBlur={this.updateFilters}
                    />
                </div>

                <div className="form-group">
                    <Input
                        type="number"
                        label="Antall kjøp fra og med:"
                        value={this.state.numPurchases}
                        name="numPurchases"
                        onChange={this.onFilterChange}
                        onBlur={this.updateFilters}
                    />
                </div>

                <div className="form-group">
                    <button
                        style={{ width: '100%' }}
                        ref="searchBtn"
                        className="btn btn-primary"
                        disabled={this.isSearchButtonDisabled()}
                        onClick={event => {
                            this.updateFilters();
                            this.props.fetchResults();
                        }}
                    >
                        Søk
                    </button>
                </div>
            </div>
        );
    }

    isSearchButtonDisabled = () => {
        return (
            this.state.postalFromError !== null ||
            this.state.postalToError !== null ||
            this.state.dateFromError !== null ||
            this.state.dateToError !== null ||
            this.getAvailableShows().length === 0
        );
    };

    renderRenterFilter = () => {
        if (!this.props.renters || !this.props.renters.length) {
            return null;
        }

        return (
            <div className="form-group">
                <Input
                    type="multiselect"
                    data={this.props.renters.map(renter => ({
                        value: renter.id,
                        label: renter.name,
                    }))}
                    label="Leietaker"
                    name="renterIds"
                    value={this.state.renterIds}
                    onChange={this.onMultiChange}
                />
            </div>
        );
    };

    renderShowsTypeFilter = () => {
        const types = this.props.shows.map(show => show.customerType).filter(i => i);

        const suggestions = Array.from(new Set(types)).map(type => ({
            value: type,
            label: type,
        }));

        if (suggestions.length === 0) {
            return null;
        }

        return (
            <div className="form-group">
                <Input
                    type="multiselect"
                    data={suggestions}
                    label="Arrangementstype"
                    name="showTypes"
                    value={this.state.showTypes}
                    onChange={this.onMultiChange}
                />
            </div>
        );
    };

    // Returns all shows that matches the other criterias
    getAvailableShows = () => {
        const selectedRenterIds = this.state.renterIds.length
            ? this.state.renterIds.map(obj => parseInt(obj.value, 10))
            : [];
        const selectedShowTypes = this.state.showTypes.length ? this.state.showTypes.map(obj => obj.value) : [];

        let firstShowIndex = findIndexDateLessThan(this.props.shows, this.state.dateTo);
        let lastShowIndex = findIndexDateLessThan(this.props.shows, this.state.dateFrom, firstShowIndex);

        const dateFilteredShows = this.props.shows.slice(firstShowIndex, lastShowIndex);
        return dateFilteredShows.filter(show => {
            if (selectedRenterIds.length && selectedRenterIds.indexOf(show.renterID) === -1) {
                return false;
            }

            if (selectedShowTypes.length && selectedShowTypes.indexOf(show.customerType) === -1) {
                return false;
            }

            return true;
        });
    };

    renderShowsFilter = () => {
        const availableShows = this.getAvailableShows();

        if (!availableShows.length) {
            return (
                <div className="alert alert-danger">
                    Ingen arrangementer faller innenfor kombinasjonen av dato, leietaker og arrangementstype. Utvid
                    søket ditt.
                </div>
            );
        }

        return (
            <div className="form-group">
                <Input
                    type="multiselect"
                    data={availableShows.map(show => ({
                        value: show.id,
                        label: show.date + ' ' + show.title,
                    }))}
                    label="Arrangementer"
                    name="showIds"
                    value={this.state.showIds}
                    onChange={this.onShowsChange}
                />
            </div>
        );
    };
}

export default CrmFilters;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActiveHeaderButton from './ActiveHeaderButton';

export default class ActiveHeaderToolbar extends Component {
    static propTypes = {
        /** @type {Array} tools - the list of tools for this toolbar */
        tools: PropTypes.arrayOf(
            PropTypes.shape({
                /** @type {String} the title for the tool */
                title: PropTypes.string,
                /** @type {Function} action - the function that should be called when this tool is activated */
                action: PropTypes.func,
                /** @type {String} icon - the name of the icon to use */
                icon: PropTypes.string,
                /** @type {Boolean} disabled - whether this tool is disabled or not */
                disabled: PropTypes.bool,
            })
        ).isRequired,
    };

    render() {
        return (
            <div className="active-header-toolbar">
                {this.props.tools.map(tool => (
                    <ActiveHeaderButton
                        value={tool.title}
                        onClick={tool.action}
                        icon={tool.icon}
                        key={tool.title}
                        disabled={tool.disabled}
                    />
                ))}
            </div>
        );
    }
}

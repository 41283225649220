import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DxTypeahead from '../../../global/components/DxTypeahead';
import DxPopup from '../../../global/components/DxPopup';
import Input from '../../../global/components/Input';

const getUnique = (arr, comp) => {
    const unique = arr
        .map(e => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e])
        .map(e => arr[e]);

    return unique;
};

export default class AddUser extends Component {
    static propTypes = {
        partners: PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    state = {
        isPending: false,
        selectedPartner: null,
        selectedUserId: null,
    };

    static defaultProps = {};

    getSelectedPartner = partnerName => {
        this.setState({
            isPending: true,
        });
        const partnerId = partnerName.split('-')[0].trim();
        axios.get('/api/v1/partners/' + partnerId).then(response => {
            this.setState({
                isPending: false,
                selectedPartner: response.data,
            });
        });
    };

    addUser = () => {
        console.log(this.state.selectedPartner.id, this.state.selectedUserId);
        if (this.state.selectedPartner && this.state.selectedUserId) {
            this.props.addUser(this.state.selectedUserId, this.state.selectedPartner.id);
        }
    };

    onClose = () => {
        this.props.onClose();
    };

    userSelected = (name, value) => {
        this.setState({
            selectedUserId: value,
        });
    };

    render() {
        const showUserSelect =
            !this.state.isPending && this.state.selectedPartner && this.state.selectedPartner.users.length > 0;
        const uniqueUsers = showUserSelect ? getUnique(this.state.selectedPartner.users, 'id') : [];
        return (
            <DxPopup
                title="Legg til bruker på partner"
                onPopupClose={this.onClose}
                onPopupConfirm={this.addUser}
                confirmDisabled={this.state.selectedPartner === null || this.state.selectedUserId === null}
            >
                <div className="form-group">
                    <DxTypeahead
                        label="Partner"
                        suggestions={this.props.partners.map(p => p.id + ' - ' + p.name)}
                        onSuggestionSelect={this.getSelectedPartner}
                    />
                </div>
                <div className="form-group">
                    <Input
                        type="select"
                        name="users"
                        label="Brukere"
                        data={uniqueUsers.map(user => ({
                            value: user.id,
                            label: user.id + ' - ' + user.firstName + ' ' + user.lastName,
                        }))}
                        value={this.state.selectedUserId}
                        onChange={this.userSelected}
                        disabled={showUserSelect}
                    />
                </div>
            </DxPopup>
        );
    }
}

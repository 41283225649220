import { saveAs } from 'file-saver';

// Appends a given file extension to the filename if it doesn't
// have it already
function appendExtension(filename, ext) {
    filename = String(filename);

    if (filename.substr(-1 - ext.length) !== '.' + ext) {
        filename += '.' + ext;
    }

    return filename;
}

function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

// Index starts on 0
function getExcelColumnName(index) {
    let dividend = index;
    let columnName = '';
    let modulo;

    while (dividend > 0) {
        modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
    }

    return columnName;
}

function arrayToSheet(data) {
    let maxRow = data.length;
    let maxColumn = 0;

    let sheet = data.reduce(function(memo, row, rowIndex) {
        maxColumn = Math.max(maxColumn, row.length);

        return row.reduce(function(m, column, colIndex) {
            let t = 's'; // Default string
            if (typeof column === 'number') {
                t = 'n';
            } else if (typeof column === 'boolean') {
                t = 'b';
            }
            m[getExcelColumnName(colIndex + 1) + (rowIndex + 1)] = {
                t: t,
                v: column,
            };
            return m;
        }, memo);
    }, {});

    sheet['!ref'] = 'A1:' + getExcelColumnName(maxColumn) + maxRow;
    sheet['!merges'] = [];
    return sheet;
}

// data should be a two dimentional array.
export function xlsx(filename, data) {
    filename = appendExtension(filename, 'xlsx');

    let workbook = {
        SheetNames: ['Eksportert epostliste'],
        Sheets: {
            'Eksportert epostliste': arrayToSheet(data),
        },
    };

    import('xlsx').then(XLSX => {
        let output = XLSX.write(workbook, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'binary',
        });
        saveAs(
            new Blob([s2ab(output)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            filename
        );
    });
}

// data should be a two dimentional array.
export function csv(filename, data, /* optional: */ fieldSeparator, rowSeparator) {
    filename = appendExtension(filename, 'csv');

    if (typeof fieldSeparator === 'undefined') {
        fieldSeparator = ',';
    }
    if (typeof rowSeparator === 'undefined') {
        rowSeparator = '\r\n';
    }

    let csv = data
        .map(function(row) {
            return row
                .map(function(field) {
                    return String(field)
                        .replace(fieldSeparator, '')
                        .replace(rowSeparator, '')
                        .trim();
                })
                .join(fieldSeparator);
        })
        .join(rowSeparator);

    // Prepend utf-8 BOM so Mailchimp understands norwegian characters.
    csv = '\ufeff' + csv;

    saveAs(
        new Blob([csv], {
            type: 'text/csv;charset=utf-8',
        }),
        filename
    );
}

export function txt(filename, data, /* optional: */ fieldSeparator, rowSeparator) {
    filename = appendExtension(filename, 'txt');

    if (typeof fieldSeparator === 'undefined') {
        fieldSeparator = '\t';
    }
    if (typeof rowSeparator === 'undefined') {
        rowSeparator = '\r\n';
    }

    let csv = data
        .map(function(row) {
            return row
                .map(function(field) {
                    return String(field)
                        .replace(fieldSeparator, '')
                        .replace(rowSeparator, '')
                        .trim();
                })
                .join(fieldSeparator);
        })
        .join(rowSeparator);

    // Prepend utf-8 BOM so Mailchimp understands norwegian characters.
    csv = '\ufeff' + csv;

    saveAs(
        new Blob([csv], {
            type: 'text/plain;charset=utf-8',
        }),
        filename
    );
}

import React from 'react';

const PartnerRole = ({ role, remove }) => {
    const removeRole = () => remove(role.id);
    return (
        <span>
            {role.name}{' '}
            <span role="button" onClick={removeRole}>
                (-)
            </span>
        </span>
    );
};

export default PartnerRole;

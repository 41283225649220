import React from 'react';

export default class GriddlePagination extends React.PureComponent {
    static defaultProps = {
        maxPage: 0,
        currentPage: 0,
        nextIconComponent: null,
        previousIconComponent: null,
        paginationWindow: 9,
    };

    pageChange = event => {
        this.props.setPage(parseInt(event.target.value, 10) - 1);
    };

    buttonClicked = name => {
        switch (name) {
            case 'toBeginning':
                this.props.setPage(0);
                break;
            case 'prev':
                this.props.setPage(Math.max(0, this.props.currentPage - 1));
                break;
            case 'toEnd':
                this.props.setPage(this.props.maxPage - 1);
                break;
            case 'next':
                this.props.setPage(Math.min(this.props.currentPage + 1, this.props.maxPage - 1));
                break;
            default:
                this.props.setPage(parseInt(name, 10));
        }
    };

    render() {
        const wnd = Math.min(this.props.paginationWindow, this.props.maxPage);
        const windowStart = Math.max(
            0,
            Math.min(this.props.maxPage - this.props.paginationWindow, this.props.currentPage - Math.floor(wnd / 2))
        );

        const toBeginning = '《';
        const prev = '〈';

        const toEnd = '》';
        const next = '〉';

        const makeButton = (content, name, classNames) => (
            <li key={name} className={classNames.join(' ')} onClick={this.buttonClicked.bind(this, name)}>
                <a>{content}</a>
            </li>
        );

        const buttons = [];
        buttons.push(
            makeButton(
                toBeginning,
                'toBeginning',
                this.props.currentPage === 0 ? ['disabled', 'hidden-xs'] : ['hidden-xs']
            )
        );
        buttons.push(makeButton(prev, 'prev', this.props.currentPage === 0 ? ['disabled'] : []));

        for (let i = 0; i < wnd; i++) {
            const pageNum = windowStart + i;

            const classNames = [];

            if (pageNum === this.props.currentPage) {
                classNames.push('active');
            }

            // On mobile, we should only display 5 numbers:
            if (
                Math.abs(this.props.currentPage - pageNum) > 2 &&
                !(this.props.currentPage < 3 && pageNum < 5) &&
                !(this.props.currentPage > this.props.maxPage - 3 && pageNum > this.props.maxPage - 6)
            ) {
                classNames.push('hidden-xs');
            }

            buttons.push(makeButton(pageNum + 1, pageNum, classNames));
        }

        buttons.push(makeButton(next, 'next', this.props.currentPage === this.props.maxPage - 1 ? ['disabled'] : []));
        buttons.push(
            makeButton(
                toEnd,
                'toEnd',
                this.props.currentPage === this.props.maxPage - 1 ? ['disabled', 'hidden-xs'] : ['hidden-xs']
            )
        );

        return (
            <div className="griddle-pagination">
                <ul>{buttons}</ul>
            </div>
        );
    }
}

import moment from 'moment';
import ASCIIFolder from 'fold-to-ascii';
import { filenamify } from './filenamify';

/**
 * Takes a string and returns a new string where the first letter is
 * capitalized.
 *
 * @param {string} string
 * @return {string}
 */
export function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Returns a date formatted like "1. august 2017"
 *
 * @param {number} timeValue
 * @return {string}
 */
export function formatDate(timeValue) {
    return moment(timeValue).format('Do MMMM YYYY');
}

/**
 * Pads the number with necessary amount of `0`-characters. Returns the result
 * as a string.
 *
 * @param {number} num
 * @param {integer} width
 * @return {string}
 */
export function zeroPad(num, width) {
    const zero = width - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
}

/**
 * Natrual compare function for norwegian strings and numbers.
 *
 * @param {string} a
 * @param {string} b
 * @return {integer} -1, 0 or 1
 */
export function norwegianCompare(a, b) {
    return String(a).localeCompare(String(b), 'nb-no', {
        numeric: true,
        usage: 'search',
    });
}

/**
 * laravel download method doesn't support æøå, so we have to ASCIIFold in addition to filenamify
 *
 * @param {string} filename
 * @return {string} validFilename
 */
export const makeLaravelSafeFileName = fileName =>
    filenamify(ASCIIFolder.foldReplacing(fileName), { replacement: '_' })
        //also remove % and del-char, because that is not removed by filenamify, but laravel doesn't like it
        .replace(/[%\x7F]/g, '_');

import React from 'react';
import PropTypes from 'prop-types';
import { connectBackbone } from '../../../global/utils/backboneUtils';
import { getCrmSearchResultsCollection } from '../../../global/collections/CrmSearchResultCollection';
import { getShowsForCrmCollection } from '../../../global/collections/ShowsForCrmCollection';
import renters from '../../../global/stores/renters';
import MainView from '../components/MainView';

const { number, array, func } = PropTypes;

@connectBackbone(
    () => getCrmSearchResultsCollection(),
    collection => {
        return {
            collection: collection.toJSON(),
            getFetchedFilters: collection.getFetchedFilters.bind(collection),
            getFilters: collection.getFilters.bind(collection),
            setFilters: collection.setFilters.bind(collection),
            fetchResults: collection.fetch.bind(collection),
            hasSynced: collection.hasSynced.bind(collection),
            searchResultsLoading: collection.loading,
        };
    },
    true // Don't fetch until user presses search button
)
@connectBackbone(({ partnerId }) => getShowsForCrmCollection(partnerId), collection => ({ shows: collection.toJSON() }))
@connectBackbone(() => renters, collection => ({ renters: collection.toJSON() }))
class CrmMainContainer extends React.PureComponent {
    static propTypes = {
        partnerId: number.isRequired,

        // from connect
        collection: array.isRequired,
        shows: array.isRequired,
        renters: array.isRequired,
        getFetchedFilters: func.isRequired,
        getFilters: func.isRequired,
        setFilters: func.isRequired,
        fetchResults: func.isRequired,
        hasSynced: func.isRequired,
        //countSyncs: func.isRequired,
    };

    render() {
        return <MainView {...this.props} />;
    }
}

export default CrmMainContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default class LinkButton extends Component {
    static propTypes = {
        text: PropTypes.string,
        type: PropTypes.oneOf(['primary', 'secondary', 'delete']),

        //props below is being passed directly to react-Link
        onClick: PropTypes.func,
        target: PropTypes.string,
        replace: PropTypes.bool,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    };

    static defaultProps = {
        text: 'Click me',
        type: 'primary',
    };

    render() {
        const classes = classNames({
            'dx-button': true,
            ['dx-button--' + this.props.type]: true,
        });
        return (
            <Link
                className={classes}
                onClick={this.props.onClick}
                target={this.props.target}
                replace={this.props.replace}
                to={this.props.to}
                innerRef={this.props.innerRef}
            >
                {this.props.text}
            </Link>
        );
    }
}

import React from 'react';
import _ from 'lodash';
import GriddlePagination from '../components/GriddlePagination';
import DefaultIteratorHeader from '../components/DefaultIteratorHeader';
import { norwegianCompare } from './stringUtils';

export default class {
    constructor(options = {}) {
        this.options = {
            itemsPerPage: 0,
            data: [],
            parentComponent: null,
            defaultHeaderComponent: DefaultIteratorHeader,
            name: null,
            ...options,
        };

        if (!this.options.parentComponent) {
            throw new Error('You have to set parentComponent in ReactIterator.');
        }
        if (!this.options.parentComponent) {
            throw new Error('You have to give the ReactIterator a name.');
        }

        this.defaultState = {
            currentPage: 1,
            sortBy: null,
            reverse: false,
        };
    }

    getState() {
        let state = {};
        if (
            this.options.parentComponent.state &&
            this.options.parentComponent.state[`ReactIterator_${this.options.name}`]
        ) {
            state = this.options.parentComponent.state[`ReactIterator_${this.options.name}`];
        }

        return {
            ...this.defaultState,
            ...state,
        };
    }

    setState(newState) {
        let state = {};
        state[`ReactIterator_${this.options.name}`] = {
            ...this.getState(),
            ...newState,
        };

        this.options.parentComponent.setState(state);
    }

    currentPage() {
        const state = this.getState();

        const maxPage = Math.ceil(this.options.data.length / this.options.itemsPerPage);

        return Math.min(state.currentPage, maxPage);
    }

    map(cb) {
        let state = this.getState();

        let data = this.options.data;
        let sortBy = state.sortBy;

        // If data is a Backbone.Collection:
        if (data.models) {
            data = data.models;

            if (sortBy && typeof sortBy === 'string') {
                const column = sortBy;
                sortBy = row => row.get(column);
            }
        }

        if (sortBy) {
            if (typeof sortBy === 'string') {
                const column = sortBy;
                sortBy = row => row[column];
            }

            data.sort((a, b) => norwegianCompare(sortBy(a), sortBy(b)));
        }
        if (state.reverse) {
            data.reverse();
        }

        const from = (this.currentPage() - 1) * this.options.itemsPerPage;
        const items = this.options.itemsPerPage || this.options.data.length;

        data = data.slice(from, from + items);

        return _.map(data, cb);
    }

    sortBy(columnOrComparator) {
        let state = this.getState();

        let reverse = false;
        let sortBy = columnOrComparator;

        if (state.sortBy && state.sortBy.toString() === sortBy.toString()) {
            if (state.reverse) {
                sortBy = null;
                reverse = false;
            } else {
                reverse = true;
            }
        }

        this.setState({
            sortBy,
            reverse,
            currentPage: 1,
        });
    }

    renderHeader(label, sortBy = null, widthPercent = null, alignment = null, component = null, key = null) {
        component = component || this.options.defaultHeaderComponent;

        let style = {};
        if (widthPercent) {
            if (typeof widthPercent === 'string') {
                style.width = widthPercent;
            } else {
                style.width = `${widthPercent}%`;
            }
        }

        if (alignment) {
            style.textAlign = alignment;
        }

        return React.createElement(
            component,
            {
                sort:
                    sortBy === this.getState().sortBy ? (this.getState().reverse ? 'descending' : 'ascending') : false,
                sortCallback: sortBy && this.sortBy.bind(this, sortBy),
                sortable: !!sortBy,
                key: key ? key : undefined,
                style,
            },
            label
        );
    }

    renderPagination() {
        const state = this.getState();

        if (this.options.itemsPerPage < 1) {
            return null;
        }

        let maxPage = Math.ceil(this.options.data.length / this.options.itemsPerPage);

        if (maxPage === 1 && this.currentPage() === 1) {
            return null;
        }

        // Griddle uses page number start on 0 (!!)
        return (
            <GriddlePagination
                maxPage={maxPage}
                currentPage={this.currentPage() - 1}
                setPage={num => this.setPage(num + 1)}
            />
        );
    }

    setPage(number) {
        this.setState({
            currentPage: number,
        });
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as ExportUtils from '../../../global/utils/ExportUtils';
import PageLayoutWithFilters from '../../../global/components/PageLayoutWithFilters';
import CrmFilters from './CrmFilters';
import DxButton from '../../../global/components/DxButton';
import ExportDialog from './ExportDialog';
import ReactIterator from '../../../global/utils/ReactIterator';
import dx from '../../../global/dx';
import LoadingIndicator from '../../../global/components/LoadingIndicator';

const EmptyView = () => (
    <div className="no-results-view container-fluid">
        <div className="flex-container-row justify-content">
            <div className="maskot-container">
                <img width="130" src="/app/desktop/src/assets/mascot/sad.png" />
            </div>

            <div>
                <h1>Vi fant ingen resultater som passer ditt søk</h1>
                <p className="subtitle">Kanskje du søkte for spesifikt?</p>

                <p>
                    Tips: Det kan være lurt å starte med ganske åpne søk, og snevre inn resultatet ved å legge på filtre
                    etterpå.
                </p>
            </div>
        </div>
    </div>
);

const InitialView = props => (
    <div className="no-results-view container-fluid">
        <div className="flex-container-row justify-content">
            <div className="maskot-container">
                <img width="130" src="/app/desktop/src/assets/mascot/questions.png" />
            </div>

            <div>
                <h1>Ingen resultater å vise (enda)</h1>
                <p className="subtitle">Fyll ut noen filtre i panelet og klikk på søk.</p>

                <p>Hvis du vil se hvordan det virker har vi gjort klart et søk for deg, bare klikk på knappen under.</p>

                <button className="btn btn-link" onClick={props.presetNewProfiles}>
                    Nye profiler siste måned
                </button>
                <br />
                <button className="btn btn-link" onClick={props.presetPurchasesLastWeek}>
                    Kjøpt billett siste uka
                </button>
            </div>
        </div>
    </div>
);

const { array, func } = PropTypes;

class MainView extends React.PureComponent {
    static propTypes = {
        collection: array.isRequired,
        shows: array.isRequired,
        renters: array.isRequired,
        getFetchedFilters: func.isRequired,
        getFilters: func.isRequired,
        setFilters: func.isRequired,
        fetchResults: func.isRequired,
        hasSynced: func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            presetFilters: null,
            showExportsPopup: false,
            loading: false,
        };
    }
    fetchResults = () => {
        this.setState({
            loading: true,
        });
        this.props.fetchResults().then(() => {
            this.setState({ loading: false });
        });
    };

    presetNewProfiles = () => {
        this.setState({
            presetFilters: {
                dateFrom: moment()
                    .subtract(1, 'month')
                    .format('DD.MM.YYYY'),
                dateTo: null,
            },
        });
    };

    presetPurchasesLastWeek = () => {
        this.setState({
            presetFilters: {
                dateFilterType: 'ticket',
                dateFrom: moment()
                    .subtract(1, 'week')
                    .format('DD.MM.YYYY'),
                dateTo: null,
            },
        });
    };

    render() {
        const propsToSend = { ...this.props, fetchResults: this.fetchResults };
        return (
            <PageLayoutWithFilters
                title="Kunder"
                filters={<CrmFilters {...propsToSend} presetFilters={this.state.presetFilters} />}
                tools={this.getExportTools()}
            >
                <div className="module-crm">{this.renderContent()}</div>

                {this.state.showExportsPopup && (
                    <ExportDialog
                        onClickExport={this.handleExport}
                        close={this.closeExportDialog}
                        numRecords={this.props.collection.length}
                    />
                )}
            </PageLayoutWithFilters>
        );
    }

    renderContent() {
        if (true === this.state.loading) {
            return <LoadingIndicator />;
        }

        if (!this.props.hasSynced()) {
            return (
                <InitialView
                    presetNewProfiles={this.presetNewProfiles}
                    presetPurchasesLastWeek={this.presetPurchasesLastWeek}
                />
            );
        }

        if (!this.props.collection.length) {
            return <EmptyView />;
        }

        const iterator = new ReactIterator({
            itemsPerPage: 25,
            data: this.props.collection,
            parentComponent: this,
            name: 'iterator',
        });

        return (
            <div>
                <table className="dx-table">
                    <thead>
                        <tr>
                            {iterator.renderHeader('Navn', row => `${row.firstName} ${row.lastName}`)}
                            {iterator.renderHeader('Epost', 'email')}
                            {/*iterator.renderHeader('Telefon', 'cellPhone')*/}
                            {iterator.renderHeader(
                                'Antall kjøp / antall billetter',
                                row => parseInt(row.purchases, 10),
                                null
                            )}
                            {iterator.renderHeader('Omsetning', row => parseFloat(row.revenue), null, 'center')}
                        </tr>
                    </thead>
                    <tbody>
                        {iterator.map((row, idx) => (
                            <tr key={idx}>
                                <td>
                                    {row.firstName} {row.lastName}
                                </td>
                                <td>{row.email}</td>
                                {/*<td>{row.mobilePhone}</td>*/}
                                <td>
                                    {row.purchases} / {row.tickets}
                                </td>
                                <td>{dx.utils.moneyFormatter(parseInt(row.totalAmount, 10) / 100)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.props.collection.length > 25 && iterator.renderPagination()}
            </div>
        );
    }

    getExportTools() {
        return [
            {
                title: 'Eksportér',
                action: () => {
                    this.setState({ showExportsPopup: true });
                },
                icon: 'files',
                disabled: !(this.props.collection && this.props.collection.length > 0),
            },
        ];
    }

    renderExportsButton() {
        let button = <DxButton icon="fa fa-files-o" style={{ marginLeft: 10 }} text="Exportér" disabled />;

        if (this.props.collection && this.props.collection.length > 0) {
            button = (
                <DxButton
                    icon="fa fa-files-o"
                    style={{ marginLeft: 10 }}
                    text={`Exportér alle ${this.props.collection.length}`}
                    onClick={() => {
                        this.setState({ showExportsPopup: true });
                    }}
                />
            );
        }
        return button;
    }

    // Only emails in txt export:
    exportTxt = (filename, data) => {
        ExportUtils.txt(
            filename,
            data.map(function(row) {
                return [row[2]];
            }),
            undefined,
            ';\r\n'
        );
    };

    handleExport = type => {
        let exportFuncs = {
            xlsx: ExportUtils.xlsx,
            csv: ExportUtils.csv,
            txt: this.exportTxt,
        };
        let data = this.props.collection.map(person => [
            String(person.firstName).trim(),
            String(person.lastName).trim(),
            String(person.email).trim(),
        ]);

        exportFuncs[type]('Epostliste', data);

        this.closeExportDialog();
    };

    closeExportDialog = () => {
        this.setState({ showExportsPopup: false });
    };
}

export default MainView;

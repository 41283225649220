import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import $ from 'jquery';
import scrollIntoViewIfNeeded from '../utils/scrollIntoViewIfNeeded';

// Typeahead for simple strings. Might expand to include images and/or more data
// TODO: Fix so that moving cursor down or up scrolls (if overflow) if needed

class DxTypeaheadSuggestion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
        };
    }

    onMouseDown = e => {
        this.props.onClick(this.props.suggestion);
    };

    onMouseEnter = e => {
        this.setState({ isHovered: true });
    };

    onMouseLeave = e => {
        this.setState({ isHovered: false });
    };

    render() {
        let classes =
            this.props.isActive || this.state.isHovered
                ? 'dx-typeahead-suggestion dx-typeahead-suggestion-active'
                : 'dx-typeahead-suggestion';
        if (this.props.isNew) {
            return (
                <li
                    className={classes}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onMouseDown={this.onMouseDown}
                >
                    Opprett{' '}
                    <strong>
                        <i>{this.props.suggestion}</i>
                    </strong>
                </li>
            );
        }
        // else
        return (
            <li
                className={classes}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onMouseDown={this.onMouseDown}
            >
                {this.props.suggestion}
            </li>
        );
    }
}

DxTypeaheadSuggestion.propTypes = {
    suggestion: PropTypes.string,
    isActive: PropTypes.bool,
    isNew: PropTypes.bool,
    onClick: PropTypes.func,
};

DxTypeaheadSuggestion.defaultProps = {
    suggestion: '',
    isActive: false,
    isNew: false,
    onClick: () => {},
};

class DxTypeahead extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHidden: true,
            userInput: this.props.defaultValue,
            activeSuggestion: null,
            suggestions: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.defaultValue) {
            this.setState({ userInput: nextProps.defaultValue || '' });
        }
    }

    onChange = e => {
        const value = e.currentTarget.value;

        const suggestions = this.getMatchingSuggestions({ ...this.state, userInput: value });

        let activeSuggestion = this.state.activeSuggestion;

        if (!activeSuggestion || !_.includes(suggestions, activeSuggestion)) {
            activeSuggestion = _.head(suggestions);
        }

        this.setState({
            activeSuggestion,
            isHidden: false,
            userInput: value,
        });
    };

    getMatchingSuggestions = (state = this.state) => {
        let suggestions = _.filter(
            this.props.suggestions,
            suggestion =>
                String(suggestion)
                    .toLowerCase()
                    .indexOf(state.userInput.toLowerCase()) !== -1
        );

        if (this.props.allowCreate && state.userInput !== '' && !_.includes(suggestions, state.userInput)) {
            suggestions.push(state.userInput);
        }

        return suggestions;
    };

    /*
     *  When the input receives focus
     */
    showSuggestions = e => {
        this.setState({ isHidden: false });
    };

    // Public function!
    clear = () => {
        this.setState({
            userInput: '',
            activeSuggestion: null,
            isHidden: true,
        });
    };

    /*
     *  When the input loses focus
     */
    hideSuggestions = e => {
        // If user empties the input and then exit field,
        // clear the selection
        if (this.state.userInput === '') {
            this.selectSuggestion();
        }
        setTimeout(() => {
            this.setState({ isHidden: true });
        }, 100);
    };

    /*
     *  When the user presses a key
     */
    onInputKey = e => {
        switch (e.keyCode) {
            case 40: // down
                e.preventDefault();
                this.moveToSuggestion('next');
                break;
            case 38: // up
                e.preventDefault();
                this.moveToSuggestion('prev');
                break;
            case 13: // enter
                this.selectSuggestion(this.state.activeSuggestion);
                break;
            case 9: // tab
                this.selectSuggestion(this.state.activeSuggestion);
                break;
            case 27: // esc
                this.handleEsc();
                break;
        }
    };

    handleEsc = () => {
        if (this.state.userInput === '') {
            this.selectSuggestion();
            let elem = this.refs['typeahead-input'];
            $(elem).blur();
        } else {
            this.selectSuggestion();
        }
    };

    /*
     *  When user moves arrows up and down the suggestions list
     */
    moveToSuggestion = direction => {
        let suggestions = this.getMatchingSuggestions();

        let count = suggestions.length;
        let next = direction === 'next';
        let newActiveSuggestion = null;
        let newIndex = 0;

        if (!this.state.activeSuggestion) {
            newIndex = next ? 0 : count;
        } else {
            for (let i = 0; i < count; i++) {
                if (suggestions[i] === this.state.activeSuggestion) {
                    newIndex = next ? i + 1 : i - 1;
                    break;
                }
            }
        }

        if (newIndex >= 0 && newIndex <= count) {
            newActiveSuggestion = suggestions[newIndex];
        }

        this.setState({ activeSuggestion: newActiveSuggestion });
    };

    selectSuggestion = suggestion => {
        if (!suggestion) {
            this.setState({ userInput: '', activeSuggestion: null });
            this.props.onSuggestionSelect('', false);
        } else {
            this.setState({ isHidden: true, userInput: suggestion });
            this.props.onSuggestionSelect(suggestion, this.isNew(suggestion));
        }
    };

    isNew = suggestion => {
        return !_.includes(this.props.suggestions, suggestion);
    };

    render() {
        let wrapperClass = 'dx-typeahead';

        if (!this.props.plain) {
            wrapperClass += ' ' + (this.props.large ? 'input-react-wrapper-lg' : 'input-react-wrapper');
        }

        let listClasses = this.state.isHidden
            ? 'dx-typeahead-suggestions dx-typeahead-suggestions-hidden'
            : 'dx-typeahead-suggestions';

        return (
            <div style={this.props.style}>
                {this.renderLabel()}
                {this.props.required && <span className="fa fa-asterisk dx-input-required-icon" />}
                <div className={wrapperClass}>
                    <input
                        ref="typeahead-input"
                        type="text"
                        value={this.state.userInput}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        onKeyDown={this.onInputKey}
                        onChange={this.onChange}
                        onFocus={(...args) => {
                            this.showSuggestions(...args);
                            this.props.onFocus(...args);
                        }}
                        onBlur={(...args) => {
                            this.hideSuggestions(...args);
                            this.props.onBlur(...args);
                        }}
                    />
                    <ul className={listClasses}>
                        {_.map(this.getMatchingSuggestions(), (suggestion, i) => (
                            <DxTypeaheadSuggestion
                                key={i}
                                suggestion={suggestion}
                                isActive={suggestion === this.state.activeSuggestion}
                                ref={suggestion === this.state.activeSuggestion ? 'active' : null}
                                isNew={this.isNew(suggestion)}
                                onClick={this.selectSuggestion}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    componentDidUpdate() {
        if (this.refs.active) {
            scrollIntoViewIfNeeded(ReactDOM.findDOMNode(this.refs.active));
        }
    }

    renderLabel() {
        if (!this.props.label) return null;
        let labelClass = this.props.large ? 'dx-label-lg dx-label' : 'dx-label';
        return <label className={labelClass}>{this.props.label}</label>;
    }
}

DxTypeahead.propTypes = {
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    suggestions: PropTypes.array,
    placeholder: PropTypes.string,
    onSuggestionSelect: PropTypes.func,
    required: PropTypes.bool,
    large: PropTypes.bool,
    style: PropTypes.object,
    allowCreate: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    plain: PropTypes.bool,
    disabled: PropTypes.bool,
};

DxTypeahead.defaultProps = {
    label: null,
    defaultValue: '',
    suggestions: [],
    placeholder: '',
    onSuggestionSelect: () => {},
    large: false,
    style: {},
    allowCreate: false, //Whether user may insert non-suggestion value
    onFocus: () => {},
    onBlur: () => {},
    disabled: false,
};

export default DxTypeahead;

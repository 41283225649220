import React from 'react';
import PropTypes from 'prop-types';
import withSideEffect from 'react-side-effect';

// Side effect component that sets the window title. Can also be used to
// manipulate the header bar when on mobile. Read about react-side-effect on
// github.

const DocumentTitle = ({ children }) => children && React.Children.only(children);

DocumentTitle.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
};

function reducePropsToState(propsList) {
    return {
        // List of all titles
        titles: propsList.map(props => props.title),
    };
}

function handleStateChangeOnClient({ titles }) {
    // Update window header
    let title = 'DX';

    if (titles.length > 0) {
        title = titles[titles.length - 1];

        if (titles.length > 1) {
            title += ' - ' + titles[0];
        }
    }

    window.document.title = title;
}

export default withSideEffect(reducePropsToState, handleStateChangeOnClient)(DocumentTitle);

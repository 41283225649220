import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PartnerRole from './PartnerRole';

export default class PartnerRoles extends Component {
    static propTypes = {
        partner: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        roles: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
        /** @type {function(integer, integer) : Promise} */
        removeRole: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    removeRole = roleId => {
        this.props.removeRole(roleId, this.props.partner.id);
    };

    render() {
        const { partner, roles } = this.props;
        return (
            <div className="row">
                <div className="col-md-4">
                    {partner.id} - {partner.name}
                </div>
                <div className="col-md-8">
                    {roles.map(role => (
                        <PartnerRole key={role.id} role={role} remove={this.removeRole} />
                    ))}
                </div>
            </div>
        );
    }
}

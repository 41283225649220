import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Paper = ({ children, padded, slim, header, subHeader, formGroup }) => {
    const classes = classNames({
        'paper-container': true,
        padded: padded,
        slim: slim,
        'form-group': formGroup,
    });
    return (
        <div className={classes}>
            {header && (
                <div className="paper-container-header">
                    {header}
                    {subHeader && <div className="paper-container-sub-header">{subHeader}</div>}
                </div>
            )}
            {children}
        </div>
    );
};

Paper.propTypes = {
    padded: PropTypes.bool,
    slim: PropTypes.bool,
    formGroup: PropTypes.bool,
    header: PropTypes.string,
    subHeader: PropTypes.string,
};

Paper.defaultProps = {
    padded: true,
    slim: false,
    formGroup: false,
    header: '',
    subHeader: '',
};

export default Paper;

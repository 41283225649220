import { renderModuleRoutes } from '../../global/init';
import React from 'react';
import { Switch, Route } from 'react-router';
import Overview from './Overview';
import NewReport from './NewReport';
import ViewReport from './ViewReport';

const ReportsAdminModule = () =>
    renderModuleRoutes(
        'reportsadmin',
        <div className="mode-reports-container">
            <Switch>
                <Route path="/" exact component={Overview} />
                <Route path="/new" exact component={NewReport} />
                <Route path="/:reportId" component={ViewReport} />
            </Switch>
        </div>
    );

export default ReportsAdminModule;

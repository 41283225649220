import axios from 'axios';
import { FETCH_PARTNERS_FAILED, FETCH_PARTNERS_PENDING, FETCH_PARTNERS_SUCCESS } from '../actions/partners';

const fetchPending = () => ({ type: FETCH_PARTNERS_PENDING });

const fetchSuccess = response => ({
    type: FETCH_PARTNERS_SUCCESS,
    payload: response.data,
});

const fetchError = error => ({
    type: FETCH_PARTNERS_FAILED,
    payload: error,
});

export const fetchPartners = () => dispatch => {
    dispatch(fetchPending());
    return axios.get('/api/v1/partners').then(
        response => {
            dispatch(fetchSuccess(response));
        },
        error => {
            dispatch(fetchError(error));
        }
    );
};

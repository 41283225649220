import Backbone from 'backbone';
import { makeUnsubscribableCollection } from '../utils/backboneUtils';

export const getShowsForCrmCollection = makeUnsubscribableCollection(
    ['partnerId'],
    partnerId => new ShowsCollection(null, {})
);

export const ShowsCollection = Backbone.Collection.extend({
    url: function() {
        return '/api/v1/shows/fast';
    },

    parse(response) {
        return response.items;
    },
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    fetchModeReportsAdmin,
    addPermissions,
    fetchRoles,
    addUserPermission,
    removeRolePermission,
    removeUserPermission,
} from '../../global/actionCreators/modeReports';
import { fetchPartners } from '../../global/actionCreators/partners';
import LoadingIndicator from '../../global/components/LoadingIndicator';
import Paper from '../../global/components/layout/Paper';
import PrimaryButton from '../../global/components/ui/buttons/PrimaryButton';
import ListRolePermissions from './ListRolePermissions';
import ListUserPermissions from './ListUserPermissions';
import AddRole from './popups/AddRole';
import AddUser from './popups/AddUser';
import LinkButton from '../../global/components/ui/buttons/LinkButton';
import ReportDetails from './ReportDetails';

class ViewReport extends Component {
    static propTypes = {};

    static defaultProps = {};

    state = {
        showAddRolePopup: false,
        showAddUserPopup: false,
    };

    componentDidMount() {
        this.props.fetchRoles();
        if (!this.props.reports[this.props.match.params.reportId]) {
            this.props.fetchReports();
        }
        if (this.props.partnersNotAsked) {
            this.props.fetchPartners();
        }
    }

    showAddRolePopup = () => {
        this.setState({
            showAddRolePopup: true,
            showAddUserPopup: false,
        });
    };

    showAddUserPopup = () => {
        this.setState({
            showAddUserPopup: true,
            showAddRolePopup: false,
        });
    };

    closePopups = () => {
        this.setState({
            showAddUserPopup: false,
            showAddRolePopup: false,
        });
    };

    /**
     * @param {integer} roleId
     * @param {integer[]} partnerIds
     */
    addRoleToPartners = (roleId, partnerIds) => {
        this.props.addPartnerPermission(this.getReportId(), roleId, partnerIds).then(() => {
            this.closePopups();
        });
    };

    /**
     *
     * @param {integer} partnerId
     * @param {integer} userId
     */
    addPartnerUser = (partnerId, userId) => {
        this.props.addUserPermission(this.getReportId(), partnerId, userId).then(() => {
            this.closePopups();
        });
    };

    getReportId = () => {
        return this.props.match.params.reportId;
    };

    removeRolePermission = (roleId, partnerId) => {
        this.props.removeRolePermission(this.getReportId(), roleId, partnerId);
    };

    removeUserPermission = (userId, partnerId) => {
        this.props.removeUserPermission(this.getReportId(), userId, partnerId);
    };

    render() {
        const reportId = this.getReportId();
        const report = this.props.reports[reportId];
        const partnerRequestFinished = !this.props.partnersIsPending && !this.props.partnersNotAsked;

        const isLoading = this.props.pending || !partnerRequestFinished || this.props.rolesPending;

        if (isLoading) {
            return <LoadingIndicator />;
        }

        if (report === undefined) {
            return (
                <Paper>
                    <p>Finner ikke rapport med id {reportId}</p>
                    <p>
                        <LinkButton to={'/'} text={'Tilbake til listen'} />
                    </p>
                </Paper>
            );
        }
        const partners = Object.values(this.props.partners);
        const activeHangarPartners = partners.filter(p => p.active && p.id < 1000);

        return (
            <Paper>
                <div className="col-md-12">
                    <ReportDetails report={report} />
                </div>
                <div className="col-md-12">
                    <PrimaryButton text="Legg til rolle" onClick={this.showAddRolePopup} />
                    <PrimaryButton text="Legg til bruker" onClick={this.showAddUserPopup} />
                </div>
                <div className="col-md-6">
                    {partnerRequestFinished && (
                        <ListRolePermissions
                            permissions={report.permissions}
                            partners={this.props.partners}
                            roles={this.props.roles}
                            removeRolePermission={this.removeRolePermission}
                        />
                    )}
                </div>
                <div className="col-md-6">
                    {partnerRequestFinished && (
                        <ListUserPermissions
                            permissions={report.permissions}
                            partners={this.props.partners}
                            removeUserPermission={this.removeUserPermission}
                        />
                    )}
                </div>
                {this.state.showAddRolePopup && (
                    <AddRole
                        onClose={this.closePopups}
                        addRole={this.addRoleToPartners}
                        partners={activeHangarPartners}
                        permissions={report.permissions}
                        roles={Object.values(this.props.roles)}
                    />
                )}
                {this.state.showAddUserPopup && (
                    <AddUser partners={activeHangarPartners} onClose={this.closePopups} addUser={this.addPartnerUser} />
                )}
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    const { modeReports, partners } = state;
    return {
        isPending: modeReports.fetchPending,
        reports: modeReports.items,
        roles: modeReports.roles,
        rolesPending: modeReports.fetchRolesPending,
        partners: partners.items,
        partnersIsPending: partners.isPending,
        partnersNotAsked: partners.notAsked,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchReports: () => dispatch(fetchModeReportsAdmin()),
        fetchPartners: () => dispatch(fetchPartners()),
        fetchRoles: () => dispatch(fetchRoles()),
        addPartnerPermission: (reportId, roleId, partnerIds) => dispatch(addPermissions(reportId, roleId, partnerIds)),
        addUserPermission: (reportId, partnerId, userId) => dispatch(addUserPermission(reportId, partnerId, userId)),
        removeRolePermission: (reportId, roleId, partnerId) =>
            dispatch(removeRolePermission(reportId, roleId, partnerId)),
        removeUserPermission: (reportId, userId, partnerId) =>
            dispatch(removeUserPermission(reportId, userId, partnerId)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewReport);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ReportDetails extends Component {
    static propTypes = {
        report: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    render() {
        const { report } = this.props;
        return (
            <React.Fragment>
                <h4>{report.title}</h4>
                <p>{report.description}</p>
                <p>Mode-key: {report.key}</p>
                <p>Tilgang til alle partnere med mode-modulen: {report.public ? 'Ja' : 'Nei'}</p>
                <p>Parametere:</p>
                {report.parameters.length > 0 && (
                    <ul>
                        {report.parameters.map(param => (
                            <li key={param.id}>{param.type_name}</li>
                        ))}
                    </ul>
                )}
            </React.Fragment>
        );
    }
}

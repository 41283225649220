import React from 'react';
import PropTypes from 'prop-types';

const DefaultIteratorHeader = ({ sort, sortCallback, sortable, children, style }) => {
    if (sortable) {
        let classNames = ['sortable', sort || ''].filter(i => i).join(' ');

        return (
            <th className={classNames} onClick={sortCallback} style={style}>
                <a>{children}</a>
            </th>
        );
    } else {
        return <th>{children}</th>;
    }
};

DefaultIteratorHeader.propTypes = {
    sort: PropTypes.oneOf(['ascending', 'descending', false]),
    sortCallback: PropTypes.func,
    sortable: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object,
};

export default DefaultIteratorHeader;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PartnerRoles from './PartnerRoles';

class ListRolePermissions extends Component {
    static propTypes = {
        permissions: PropTypes.object.isRequired,
        partners: PropTypes.array.isRequired,
        /** @type {function(integer, integer) : Promise} */
        removeRolePermission: PropTypes.func.isRequired,
    };

    render() {
        const { permissions, roles, partners, removeRolePermission } = this.props;
        return (
            <div>
                {Object.keys(permissions).map(partnerId => {
                    const assignedRoles = permissions[partnerId].roles.map(roleId => roles[roleId]);
                    const partner = partners.find(p => p.id === Number(partnerId));
                    if (assignedRoles.length === 0) {
                        return null;
                    }
                    return (
                        <PartnerRoles
                            key={partnerId}
                            partner={partner}
                            roles={assignedRoles}
                            removeRole={removeRolePermission}
                        />
                    );
                })}
            </div>
        );
    }
}

export default ListRolePermissions;

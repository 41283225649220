import React from 'react';
import PropTypes from 'prop-types';
import SetWindowTitle from './SetWindowTitle';
import ActiveHeader from '../../global/components/ActiveHeader';
import ActiveHeaderToolbar from './ActiveHeader/ActiveHeaderToolbar';
import { connect } from 'react-redux';

class PageLayoutWithFilters extends React.PureComponent {
    state = {
        filtersPopupOpen: false,
    };

    render() {
        let tools = [];
        // this will only trigger on re-render, so it's sub optimal,
        // but this entire component will probably be switched out at some
        // point in the near (?) future.
        // Recommend moving this into redux store and connecting to it
        // where relevant.
        if (this.props.screen.isMobile) {
            tools.push({
                title: 'Filter',
                icon: 'search',
                action: this.toggleFilters,
            });
        }
        tools = [].concat(this.props.tools, tools);
        return (
            <SetWindowTitle title={this.props.title}>
                <div className="page-layout-with-filters" onClick={this.closeFilters}>
                    {!this.props.disableTitleBar && (
                        <ActiveHeader title={this.props.title}>
                            <ActiveHeaderToolbar tools={tools} />
                        </ActiveHeader>
                    )}

                    <div className="plwf-content">
                        <div
                            className={`plwf-sidebar ${this.state.filtersPopupOpen ? '' : 'plwf-hideFilterPopup'}`}
                            onClick={ev => {
                                ev.stopPropagation();
                            }}
                        >
                            <div className="plwf-filterHeader">
                                <h1>Filter</h1>
                                <div className="btn btn-link" onClick={this.closeFilters}>
                                    Lukk
                                </div>
                            </div>
                            {this.props.filters}
                        </div>

                        <div className="plwf-contentContent">{this.props.children}</div>
                    </div>
                </div>
            </SetWindowTitle>
        );
    }

    toggleFilters = ev => {
        this.setState({ filtersPopupOpen: !this.state.filtersPopupOpen });
        ev.stopPropagation();
    };

    closeFilters = () => {
        this.setState({ filtersPopupOpen: false });
    };
}

PageLayoutWithFilters.propTypes = {
    title: PropTypes.string.isRequired,
    buttons: PropTypes.node,
    filters: PropTypes.node.isRequired,
    disableTitleBar: PropTypes.bool,
    children: PropTypes.node.isRequired,
    customHeader: PropTypes.node,
    tools: PropTypes.array,
};

PageLayoutWithFilters.defaultProps = {
    tools: [],
};

const mapStateToProps = state => {
    const { screen } = state.ui;

    return {
        screen,
    };
};

export default connect(mapStateToProps)(PageLayoutWithFilters);

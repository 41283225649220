import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class ReportListItem extends Component {
    static propTypes = {
        report: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    navigate = () => {
        this.props.history.push('/' + this.props.report.id);
    };

    render() {
        const { report } = this.props;
        return (
            <div className="mode-reports-list-item" onClick={this.navigate}>
                <p key={report.id}>
                    {report.id} - {report.title}
                </p>
            </div>
        );
    }
}

export default withRouter(ReportListItem);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../../global/components/Input';
import Paper from '../../global/components/layout/Paper';
import PrimaryButton from '../../global/components/ui/buttons/PrimaryButton';
import axios from 'axios';
import { connect } from 'react-redux';
import { createModeReport, fetchParamTypes } from '../../global/actionCreators/modeReports';

class NewReport extends Component {
    static propTypes = {};

    static defaultProps = {};

    state = {
        data: {
            key: null,
            title: null,
            description: null,
            public: false,
            parameters: [],
        },
        isPending: false,
        error: null,
    };

    componentDidMount() {
        this.props.fetchParamTypes();
    }

    onChange = (name, value) => {
        this.setState(state => ({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        }));
    };

    createReport = () => {
        const newReport = this.state.data;

        this.props.createModeReport(newReport, this.props.history);
    };

    errorMessage = status => {
        if (status === 422) {
            return 'Noen av de påkrevde feltene mangler.';
        }
        return 'Det skjedde en feil';
    };

    paramTypeSelected = (name, value) => {
        this.setState(state => ({
            ...state,
            data: {
                ...state.data,
                parameters: state.data.parameters.concat([value]),
            },
        }));
    };

    render() {
        const availableParamTypes = Object.values(this.props.paramTypes)
            .filter(type => this.state.data.parameters.indexOf(type.id) < 0)
            .map(type => ({
                label: type.name,
                value: type.id,
            }));

        return (
            <Paper>
                <div className="form-group">
                    <Input
                        type="text"
                        name="key"
                        label="Rapport-nøkkel fra Mode"
                        onChange={this.onChange}
                        value={this.state.data.key}
                        required
                    />
                    <Input
                        type="text"
                        name="title"
                        label="Navn på rapporten"
                        onChange={this.onChange}
                        value={this.state.data.title}
                        required
                    />
                    <Input
                        type="text"
                        name="description"
                        label="Beskrivelse"
                        onChange={this.onChange}
                        value={this.state.data.description}
                        required
                    />
                    <Input
                        name="paramtypes"
                        type="select"
                        data={availableParamTypes}
                        label="Parametertyper"
                        onChange={this.paramTypeSelected}
                    />
                    {this.state.data.parameters.length > 0 && (
                        <ul>
                            {this.state.data.parameters.map(t => (
                                <li key={t}>{this.props.paramTypes[t].name}</li>
                            ))}
                        </ul>
                    )}
                    <Input
                        type="checkbox"
                        name="public"
                        label="Tilgjengelig for alle brukere (enda ikke støttet)"
                        onChange={this.onChange}
                        value={this.state.data.public}
                    />
                    <PrimaryButton text={'Lagre'} onClick={this.createReport} disabled={this.props.isPending} />
                    {this.props.error && <p>{this.errorMessage(this.props.error)}</p>}
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    const { modeReports } = state;
    return {
        isPending: modeReports.createPending,
        error: modeReports.createError,
        paramTypes: modeReports.paramTypes,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createModeReport: (report, history) => dispatch(createModeReport(report, history)),
        fetchParamTypes: () => dispatch(fetchParamTypes()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewReport);

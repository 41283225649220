import Backbone from 'backbone';
import { makeUnsubscribableCollection } from '../utils/backboneUtils';
import dx from '../../global/dx';

const RenterModel = Backbone.Model.extend({
    initialize(attributes, options) {
        this.partnerId = attributes.partnerId
            ? attributes.partnerId
            : this.collection
            ? this.collection.partnerId
            : options.partnerId
            ? options.partnerId
            : dx.utils.getPartnerId();
    },

    url() {
        return `/api/v1/partners/${this.partnerId}/renters${this.id ? '/' + this.id : ''}`;
    },
});

export const getRentersCollection = makeUnsubscribableCollection(
    ['partnerId'],
    partnerId => new RentersCollection(null, { partnerId })
);

export const RentersCollection = Backbone.Collection.extend({
    model: RenterModel,

    initialize(models, options) {
        this.partnerId = options.partnerId;
    },

    url() {
        return `/api/v1/partners/${this.partnerId}/renters?perPage=10000`;
    },

    parse(response) {
        return response.data;
    },
});

export default RentersCollection;

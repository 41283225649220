import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../global/components/ui/buttons/PrimaryButton';
import { connect } from 'react-redux';
import { fetchModeReportsAdmin } from '../../global/actionCreators/modeReports';
import ReportList from './ReportList';
import LoadingIndicator from '../../global/components/LoadingIndicator';
import Paper from '../../global/components/layout/Paper';

class Overview extends Component {
    static propTypes = {};

    static defaultProps = {};

    state = {
        reports: [],
        isPending: false,
    };

    createReport = () => {
        this.props.history.push('/new');
    };

    componentDidMount() {
        this.props.fetchReports();
    }

    render() {
        return (
            <React.Fragment>
                <Paper>
                    <h3>BETA</h3>
                    <h4>
                        Denne modulen er i beta, og skal administrere integrasjonen av Mode Analytics-rapporter i dxWeb.
                        Hvis du ikke vet hva dette er, betyr det at du kan se deg rundt, men ikke røre noe.
                        <br />
                        <br />
                        Du vet ikke nødvendigvis hva du ødelegger.
                    </h4>
                </Paper>
                <br />
                <Paper>
                    <PrimaryButton text={'Opprett ny rapport'} onClick={this.createReport} />
                    {this.props.isPending && this.props.notAsked && (
                        <LoadingIndicator message={'Henter rapporter...'} />
                    )}
                    <ReportList reports={this.props.reports} />
                </Paper>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { modeReports } = state;
    return {
        notAsked: modeReports.fetchNotAsked,
        isPending: modeReports.fetchPending,
        reports: modeReports.items,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchReports: () => dispatch(fetchModeReportsAdmin()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview);
